@import "../../styles/variables";
@import "../../styles/mixins";
.serviceInfo {
  padding: 32px 0;
  background-color: #f4f7fb;
  .title {
    padding: 0 24px;
  }
  .section {
    padding: 0 8px 0 32px;
    display: flex;
    justify-content: space-between;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .description {
        margin-top: 8px;
      }
    }
    .gifWrapper {
      position: relative;
      width: vw(160px);
      height: vw(140px);
      @include desktop {
        width: desktop-px(160px);
        height: desktop-px(140px);
      }
      .gif {
        z-index: 1;
        opacity: 1;
        transition: opacity 0.2s linear;
      }
      .stop {
        opacity: 0;
      }
      .serviceImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .img {
        opacity: 0;
        transition: opacity 0.2s linear;
      }
      .show {
        opacity: 1;
      }
    }
  }
  .border {
    margin: 0 24px;
    border-bottom: 1px solid $gray2;
  }
}
