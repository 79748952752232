@import "../../../styles/variables";
.fieldSet {
  display: flex;
  .label {
    display: inline-block;
    line-height: 24px;
    min-width: 60px;
    font-size: 14px;
    color: $gray3;
    margin-right: 16px;
  }
  .values {
    display: inline-flex;
    flex-direction: column;
    line-height: 24px;
    .value {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $navy;
      word-break: keep-all;
    }
  }
}

.hidden {
  opacity: 0;
}
