@import "../../styles/variables";

.typography {
  display: inline-block;
  font-size: 14px;
  color: #272e40;
}

.h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
}

.h2 {
  font-weight: bold;
  color: $gray4;
  font-size: 14px;
  line-height: 16px;
}

.h3 {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.subTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.71;
}

.body1 {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
}

.body2 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
  color: $gray4;
}

.body3 {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: normal;
  color: $blue;
}
