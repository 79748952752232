@import "../../styles/variables";
@import "../../styles/mixins";

.nonAuthenticated {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $gray4;
}
.container {
  margin: 0 auto;
  max-width: 640px;
  .header {
    height: 40px;
    background-color: $blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    color: white;
    font-weight: bold;
    .logo {
      width: 3rem;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    background-color: #f7f8fb;
  }
  @include desktop {
    border-left: solid 1px #e9edf4;
    border-right: solid 1px #e9edf4;
    box-shadow: 0 0 15px 0 rgba(black, 0.08);
  }
}

.loaderWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
