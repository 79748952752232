@import "../../../styles/variables";
@import "../../../styles/mixins";

.hangle {
  font-size: 18px;
  vertical-align: middle;
  padding-left: 2px;
  text-shadow: rgba(#b1b3bb, 0.5) 0.5px 0.5px 0.2px,
    rgba(#b1b3bb, 0.5) -0.5px -0.5px 0.2px;
  @include safari-only {
    text-shadow: rgba(#b1b3bb, 0.3) 1px 1px, rgba(#b1b3bb, 0.3) -1px -1px;
  }
}
.number {
  font-size: 24px;
  vertical-align: middle;
  text-shadow: rgba(#b1b3bb, 0.5) 0.5px 0.5px 0.2px,
    rgba(#b1b3bb, 0.5) -0.5px -0.5px 0.2px;
  @include safari-only {
    text-shadow: rgba(#b1b3bb, 0.3) 1px 1px, rgba(#b1b3bb, 0.3) -1px -1px;
  }
}

.tail {
  margin-left: 12px;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  background-color: rgba($gray2, 0.5);
  vertical-align: middle;
  box-shadow: 0.3px 0.5px  1px 0 rgba(65, 77, 107, 0.7), 0.3px 0.5px  1px 0 rgba(65, 77, 107, 0.7);
}
.leftDot {
  position: absolute;
  left: 6px;
}
.rightDot {
  position: absolute;
  right: 6px;
}
.border {
  border-radius: 4px;
  position: absolute;
  top: 0.3px;
  bottom: 0.3px;
  left: 0.3px;
  right: 0.3px;
  box-shadow: rgba($navy, 0.8) 0px 0px 0px 1.5px inset;
}

.electric {
  background: linear-gradient(
    90deg,
    #82c7ef,
    #94d3f6,
    #bae0f7,
    #94d3f6,
    #82c7ef
  );
  .leftDot {
    background-color: white;
  }
  .rightDot {
    visibility: hidden;
  }

  .hangle {
    @include safari-only {
      text-shadow: rgba($navy, 0.15) 1px 1px 1px, rgba($navy, 0.15) -1px -1px 1px;
    }
  }
  .number {
    @include safari-only {
      text-shadow: rgba($navy, 0.15) 1px 1px 1px, rgba($navy, 0.15) -1px -1px 1px;
    }
  }
}
