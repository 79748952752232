@import "../../styles/variables";
@import "../../styles/mixins";

.carNumberPlaceHolder {
  z-index: 99999;
  position: fixed;
  top: 30px;
  left: 16px;
  @include desktop {
    left: calc((100vw - 640px) / 2 + 16px);
  }
}
