.warning {
  height: 50px;
  box-sizing: border-box;
  position: absolute;
  margin: 0 24px;
  top: 60px;
  border: solid #f5b923 1px;
  border-radius: 4px;
  padding: 6px 4px 6px 32px;
  font-size: 12px;
  white-space: pre-wrap;
  width: calc(100% - 48px);
  color: #f5b923;
  background: #fffcf6;
  background-position: left 8px top 8px;
  background-repeat: no-repeat;
  background-size: 16px;
  line-height: 1.45;
  opacity: 0;

  &.play {
    animation-name: appear;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
    animation-direction: alternate;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
