$gray0: #fbfcff;
$gray1: #f7f8fb;
$gray2: #e9edf4;
$gray3: #8599b8;
$gray4: #869ab7;
$gray5: #ccd8e8;

$blue: #396eff;
$navy: #272e40;

$breakpoint-desktop: 640px;

@function vw($px-vw, $base-vw: 360px) {
  @return ($px-vw * 100vw) / $base-vw;
}

@function desktop-px($px, $base-vw: 360px) {
  @return $px * $breakpoint-desktop / $base-vw;
}
