@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  margin-top: 16px;
  .inspectionImages {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;

    .inspectionImage {
      width: calc((100vw - 112px) * 0.9 / 5);
      height: calc((100vw - 112px) * 0.9 / 5);
      margin-bottom: 8px;
      border-radius: 4px;
      &:not(:nth-child(5n)) {
        margin-right: calc((100vw - 112px) * 0.085 / 4);
      }
      @include desktop {
        width: calc((640px - 112px) * 0.9 / 5);
        height: calc((640px - 112px) * 0.9 / 5);
        &:not(:nth-child(5n)) {
          margin-right: calc((640px - 112px) * 0.085 / 4);
        }
      }
    }
  }
  .reductionWrapper {
    margin-top: 16px;
    .reduction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: $gray4;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .contentWrapper {
        display: flex;
        font-size: 12px;
        line-height: 18px;
        word-break: keep-all;
        .bracket {
          margin-right: 4px;
        }
      }
      .price {
        word-break: keep-all;
        margin-left: 10px;
      }
    }
  }
}
