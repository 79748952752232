@import "../../../styles/variables";

.similarCar {
  border-radius: 4px;
  border: solid 1px $gray2;
  box-shadow: 0 -4px 8px 0 rgba(65, 77, 107, 0.08);
  box-sizing: border-box;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .summary {
    padding: 12px 16px 12px 12px;
    display: flex;
    position: relative;
    .mainImageWrapper {
      width: 56px;
      height: 56px;
      object-fit: cover;

      margin-right: 16px;
    }
    .mainImage {
      border-radius: 4px;
    }
    .content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .section1 {
        display: flex;
        justify-content: space-between;
        .border {
          display: inline-block;
          border-right: 1px solid $gray5;
          height: 12px;
          margin: 0 4px;
        }
        .highestBidPrice {
          display: inline-block;
          font-weight: bold;
          font-size: 16px;
          color: $blue;
          line-height: 1;
        }
      }
      .section2 {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .arrow {
          transition: transform 0.3s ease-in-out;
        }
        .openArrow {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .detail {
    border-top: solid 1px $gray2;
    background-color: $gray0;
    padding: 16px 12px;
    .fieldSet {
      line-height: 24px;
      display: flex;
      .label {
        min-width: 60px;
        font-size: 14px;
        color: $gray3;
      }
      .value {
        font-size: 14px;
      }
      .options {
        line-height: 24px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .footer {
      color: #afc2db;
      font-size: 12px;
      margin-top: 16px;
    }
  }
}

.open {
  max-height: 300px;
}
