@import "../../styles/variables";
@import "../../styles/mixins";

.banner {
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(65, 77, 107, 0.3);
  border: solid 1px $gray1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .contentWrapper {
    display: flex;
    font-size: 12px;
    .thumbnail {
      width: 44px;
      height: 44px;
      margin-right: 12px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  .installBtn {
    height: 48px;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $blue;
    font-weight: bold;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(57, 110, 255, 0.32);
    word-break: keep-all;
  }
}
