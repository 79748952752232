@import "../../../styles/mixins";
@import "../../../styles/variables";

.hangle {
  font-size: 18px;
  vertical-align: middle;
  padding-left: 2px;
  text-shadow: rgba($navy, 0.4) 0.5px 0.5px 0.2px,
    rgba($navy, 0.4) -0.5px -0.5px 0.2px;
}
.number {
  font-size: 24px;
  vertical-align: middle;
  text-shadow: rgba($navy, 0.4) 0.5px 0.5px 0.2px,
    rgba($navy, 0.4) -0.5px -0.5px 0.2px;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  background-color: #247757;
  vertical-align: middle;
  box-shadow: 0.2px 1px 1px 0.5px rgba(0, 0, 0, 0.35),
    -0.2px -0.5px 1px 1px rgba(255, 255, 255, 0.2);
  text-shadow: rgba($navy, 0.2) 0.7px 0.7px 0.7px;
}
.leftDot {
  margin-left: 4px;
  margin-right: 6px;
}
.rightDot {
  margin-left: 6px;
  margin-right: 4px;
}
.border {
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: rgba($navy, 0.8) 0px 0px 0px 1.2px inset;
}

.old {
  width: 85px;
  padding: 4px 8px;
  flex-direction: column;
  color: white;
  background-color: #247757;
  .row {
    display: flex;
    align-items: center;
  }
  .regionWrapper {
    display: flex;
    .region {
      font-size: 12px;
      text-shadow: rgba($navy, 0.4) 0.5px 0.5px 0.2px,
        rgba($navy, 0.4) -0.5px -0.5px 0.2px;
    }
    margin-right: 4px;
  }
  .head {
    display: inline-block;
    font-size: 12px;
    line-height: 1.6;
  }
}
