.tooltip {
  display: flex;
  flex-direction: column;
  min-width: 164px;
  //height: 90px;
  border: solid #e9edf4 1px;
  box-shadow: 0 2px 4px 0 rgba(#414d6b, 0.08);
  padding: 12px;
  box-sizing: border-box;
  position: absolute;
  z-index: 9999;
  border-radius: 6px;
  left: 0;
  top: 0;
  background: #ffffff;

  .title {
    color: #869ab7;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .content {
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    border-top: 0 solid transparent;
    border-bottom: 6px solid #e9edf4;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    width: 0;
    top: -6px;
    right: 16px;
    margin: auto;
  }

  &::after {
    content: '';
    position: absolute;
    border-top: 0 solid transparent;
    border-bottom: 6px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    width: 0;
    top: -4px;
    right: 16px;
    margin: auto;
  }
}
