@import "../../styles/variables";
@import "../../styles/mixins";

.inspectCarInfo {
  background: $gray0;
  padding: 104px 24px 30px 24px;
  box-sizing: border-box;
  width: 100vw;
  min-height: calc(100vh - 40px - 76px);
  max-width: 640px;
  @include desktop {
    border-left: solid 1px #e9edf4;
    border-right: solid 1px #e9edf4;
    box-shadow: 0 0 15px 0 rgba(black, 0.08);
  }
}

.border {
  margin-top: 16px;
  border-bottom: 1px solid #f7f8fb;
  margin-bottom: 16px;
}
