@import "../../styles/variables";

.imageLoader {
  position: relative;

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $gray1;
    transition: opacity 0.6s ease-in-out;

    &.disabled {
      opacity: 0;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    transition: opacity 0.6s ease-in-out;
  }
}
