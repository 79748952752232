@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  margin: 0 auto;
  max-width: 640px;
  .chart {
    position: relative;
    height: 408px;
    z-index: 1;
    background: white;
    box-shadow: 0 -2px 12px rgba(65, 77, 107, 0.16);
    transition: margin-top 0.4s ease-in-out;
  }
  .faqBorder {
    border-bottom: 1px solid #f7f8fb;
    margin: 0 24px;
  }
  .bannerWrapper {
    z-index: 9;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 72px;
    @include desktop {
      width: calc(640px - 32px);
    }
  }
  @include desktop {
    border-left: solid 1px #e9edf4;
    border-right: solid 1px #e9edf4;
    box-shadow: 0 0 15px 0 rgba(black, 0.08);
  }
}
