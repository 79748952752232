@import "../../../styles/mixins";
@import "../../../styles/variables";

.carNumberPosition {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  position: fixed;
  top: 30px;
  left: 16px;
  width: 145px;
  background-color: white;
  font-weight: bolder;
  border-radius: 4px;
  padding: 3px 4px 4px 4px;
  color: #272e40;
  box-shadow: rgba($navy, 0.2) 0px 0px 1px 1px;
  @include desktop {
    left: calc((100vw - 640px) / 2 + 16px);
  }
}
.inspector {
  top: 70px;
}
