@import "../../../styles/variables";

.showMoreWrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  .showMore {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 80px;
    text-align: center;
    flex-wrap: nowrap;
    word-break: keep-all;
    display: flex;
    align-items: center;
    color: $gray4;
    font-size: 12px;
    padding: 16px;
    //transition: opacity 0.3s ease-in-out;
    //transition-delay: 1s;
    .label {
      position: absolute;
      opacity: 0;
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
      }
    }
    .noOpen {
      opacity: 1;
    }
    .open {
      opacity: 1;
    }
    .reverseArrow {
      transform: rotate(-180deg);
    }
  }
}
