@import "../../styles/variables";

.similarCars {
  background-color: white;
  padding: 24px 24px 40px 24px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .info {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $gray4;
      span {
        margin-left: 6px;
      }
    }
  }
  .showMore {
    padding: 16px;
    border-radius: 4px;
  }
  .showMoreButton {
    cursor: pointer;
    margin-top: 16px;
    position: relative;
    .text {
      margin-right: 8px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid $gray2;
    color: $gray4;
    padding: 16px;
    box-shadow: 0 2px 4px 0 rgba(65, 77, 107, 0.08);
  }
}
