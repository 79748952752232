@import "../../styles/variables";
@import "../../styles/mixins";

.carInfo {
  background: $gray0;
  padding: 104px 24px 30px 24px;
  box-sizing: border-box;
  position: absolute;
  width: 100vw;
  max-width: 640px;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  transition: height 0.5s ease-in-out;
  @include desktop {
    border-left: solid 1px #e9edf4;
    border-right: solid 1px #e9edf4;
    box-shadow: 0 0 15px 0 rgba(black, 0.08);
  }
}

.open {
  position: absolute;
}

.dummy {
  visibility: hidden;
}
.border {
  margin-top: 16px;
  border-bottom: 1px solid #f7f8fb;
  margin-bottom: 16px;
}
