@import '../../../styles/variables';
@import "../../../styles/mixins";

.review {
  padding: 16px;
  border: 1px solid $gray2;
  box-shadow: 0 1px 2px 0 rgba(65, 77, 107, 0.08);
  border-radius: 4px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .star {
    margin-bottom: 8px;
    &:not(:last-child) {
      margin-right: 2px;
    }
  }
  .contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
      font-size: 14px;
      line-height: 23px;
      color: #262e42;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .dummyContent {
      -webkit-line-clamp: unset;
      position: absolute;
      font-size: 14px;
      line-height: 1.71;
      visibility: hidden;
      overflow: auto;
    }
    .showMore {
      position: relative;
      font-size: 12px;
      color: $gray4;
      padding: 4px 8px;
    }
    .isOpen {
      overflow: auto;
      -webkit-line-clamp: unset;
    }
  }
  .carImageWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .carImage {
      width: vw(64px);
      height: vw(64px);
      object-fit: cover;
      border-radius: 4px;
      @include desktop {
        width: desktop-px(64px);
        height: desktop-px(64px);
      }
    }
  }
  .trade {
    margin-top: 16px;
    padding: 16px;
    border: 1px solid $gray2;
    background-color: $gray1;
    border-radius: 4px;
    .fieldSet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1;
      .label {
        font-weight: bold;
        font-size: 14px;
      }
      .value {
        font-size: 14px;
      }
      &:first-child {
        margin-bottom: 12px;
      }
    }

    .border {
      margin: 16px 0;
      border-bottom: solid 1px #e9edf4;
    }
    .footer {
      .noReduction {
        font-size: 12px;
        line-height: 20px;
      }
      .hasReduction {
        display: flex;
        justify-content: space-between;
        .from {
          font-size: 12px;
          line-height: 1.33;
          color: $gray4;
        }
        .mark {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: $blue;
          font-weight: bold;
          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
