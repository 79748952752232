@import '../../styles/variables';

.reviews {
  padding: 32px 24px 0 24px;
  background-color: white;
  .title {
    margin-bottom: 16px;
  }
  .description {
    padding: 40px 0;
    font-size: 12px;
    color: $gray4;
    text-align: center;
  }
}
