@import "../../styles/variables";

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 940px) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1800px) {
      @content;
    }
  }
}

.dimmer {
  max-width: 640px;
  margin: 0 auto;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background-color: rgba(#33272e40, 0.8);
  z-index: 9999999;

  .modalContent {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .header {
      z-index: 1;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      background-color: rgba(white, 0.9);
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      color: #272e40;
      font-weight: bold;
      margin-bottom: 16px;
      position: absolute;

      .closeButtonWrapper {
        position: relative;
        margin-right: 8px;
        cursor: pointer;

        .closeButton {
          padding: 12px;
        }
      }
    }

    .contentWrapper {
      padding: 48px 24px 24px 24px;
      position: relative;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      .body {
        margin-top: 14px;

        .info {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: $gray4;
          margin-bottom: 12px;

          span {
            margin-left: 6px;
          }
        }

        .similarCarPlaceholder {
          display: flex;
          padding: 12px 16px 12px 12px;
          height: 80px;
          box-sizing: border-box;
          border: solid 1px $gray2;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            justify-content: space-around;

            .firstRow {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
