@import "../../../styles/variables";
@import "../../../styles/mixins";

.hangle {
  font-size: 18px;
  vertical-align: middle;
  padding-left: 2px;
  text-shadow: rgba($navy, 0.2) 0.5px 0.5px 0.2px, rgba($navy, 0.2) -0.5px -0.5px 0.2px;
  @include safari-only {
    text-shadow: rgba($navy, 0.1) 1px 1px 1px, rgba($navy, 0.1) -1px -1px 1px;
  }
}
.number {
  font-size: 24px;
  vertical-align: middle;
  text-shadow: rgba($navy, 0.2) 0.5px 0.5px 0.2px, rgba($navy, 0.2) -0.5px -0.5px 0.2px;
  @include safari-only {
    text-shadow: rgba($navy, 0.1) 1px 1px 1px, rgba($navy, 0.1) -1px -1px 1px;
  }
}
.tail {
  margin-left: 10px;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  background-color: rgba($gray2, 0.5);
  vertical-align: middle;
  box-shadow: 0 1px 2px 0 rgba(65, 77, 107, 0.6);
}
.leftDot {
  position: absolute;
  left: 6px;
}
.rightDot {
  position: absolute;
  right: 6px;
}
.border {
  border-radius: 4px;
  position: absolute;
  top: 0.6px;
  bottom: 0.6px;
  left: 0.6px;
  right: 0.6px;
  box-shadow: rgba($navy, 0.8) 0px 0px 0px 1.5px inset;
}

.business {
  background-color: #f0b218;
  .regionWrapper {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-right: 4px;
    .region {
      line-height: 1;
      display: inline-block;
    }
  }
  .dot {
    background-color: rgba(#f0b218, 0.5);
  }
}
